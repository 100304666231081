<template>
  <div>
    <base-material-tabs color="secondary" class="my-5" grow>
      <v-tab v-for="(tab, i) in tabs" :key="i">
        {{ tab }}
      </v-tab>
    </base-material-tabs>
  </div>
</template>

<script>
import { gql } from "apollo-boost";

export default {
  components: {},
  data() {
    return {
      tab: 0,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
